/*=========================================================================================
    File Name: ext-component-swiper.scss
    Description: swiper plugin scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/* Swiper css */
/* ---------- */
/* swiper slide shadow */
.swiper-container .swiper-shadow {
  box-shadow: 2px 8px 10px 0 rgba(25, 42, 70, 0.13) !important; }

.swiper-pagination .swiper-pagination-bullet:focus {
  outline: none; }

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #7367f0; }

.swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #7367f0; }

.swiper-centered-slides.swiper-container .swiper-slide {
  text-align: center;
  font-weight: 500;
  background-color: #fff;
  height: auto;
  width: auto !important;
  padding: 2rem 5.5rem;
  cursor: pointer; }

.swiper-centered-slides.swiper-container .swiper-slide.swiper-slide-active {
  border: 2px solid #7367f0; }

.swiper-centered-slides.swiper-container .swiper-slide.swiper-slide-active i,
.swiper-centered-slides.swiper-container .swiper-slide.swiper-slide-active svg {
  color: #7367f0; }

.swiper-centered-slides .swiper-button-next:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }

.swiper-centered-slides .swiper-button-prev:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E"); }

.swiper-centered-slides .swiper-button-next,
.swiper-centered-slides .swiper-button-prev {
  height: 40px !important;
  width: 40px !important; }

.swiper-centered-slides .swiper-button-next:after,
.swiper-centered-slides .swiper-button-prev:after {
  border-radius: 50%;
  background-color: #7367f0;
  box-shadow: 0 2px 4px 0 rgba(34, 41, 47, 0.5) !important;
  background-size: 24px !important;
  height: 40px !important;
  width: 40px !important; }

.swiper-centered-slides.swiper-container-rtl .swiper-button-next:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E"); }

.swiper-centered-slides.swiper-container-rtl .swiper-button-prev:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }

.swiper-centered-slides-2.swiper-container .swiper-slide {
  font-weight: 500;
  background-color: #f2f4f4;
  height: auto;
  width: auto !important;
  cursor: pointer; }

.swiper-centered-slides-2.swiper-container .swiper-slide.swiper-slide-active {
  color: #fff;
  background-color: #7367f0 !important;
  box-shadow: 0 3px 6px 0 rgba(115, 103, 240, 0.5) !important; }

/* cube effect */
.swiper-cube-effect.swiper-container {
  width: 300px;
  left: 50%;
  margin-left: -150px;
  margin-top: -12px; }

/* swiper coverflow slide width */
.swiper-coverflow.swiper-container .swiper-slide {
  width: 300px; }

.gallery-thumbs {
  padding: 10px 0;
  background: #22292f; }

.gallery-thumbs .swiper-slide {
  opacity: 0.4; }

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1; }

.swiper-parallax .swiper-slide {
  padding: 2.67rem 4rem; }

.swiper-parallax .swiper-slide .title {
  font-size: 1.07rem;
  padding: 0.5rem 0; }

.swiper-parallax .swiper-slide .text {
  font-size: 1rem; }

.swiper-parallax .parallax-bg {
  position: absolute;
  width: 130%; }

.swiper-virtual.swiper-container {
  height: 300px; }

.swiper-virtual.swiper-container .swiper-slide {
  /* virtual slides  */
  font-size: 1.5rem;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center; }

.swiper-button-prev,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none;
  color: #fff;
  width: 38px;
  font-size: 2rem; }

.swiper-button-prev:focus,
.swiper-button-next:focus,
.swiper-container-rtl .swiper-button-prev:focus,
.swiper-container-rtl .swiper-button-next:focus {
  outline: none; }

.swiper-button-prev:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 44px;
  color: #6e6b7b;
  width: 44px;
  height: 44px;
  content: '';
  padding-right: 1px; }

.swiper-button-next:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 44px;
  color: #6e6b7b;
  width: 44px;
  height: 44px;
  content: '';
  padding-right: 2px; }

.swiper-container-rtl .swiper-button-prev:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 44px;
  color: #6e6b7b;
  width: 44px;
  height: 44px;
  content: ''; }

.swiper-container-rtl .swiper-button-next:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 44px;
  color: #6e6b7b;
  width: 44px;
  height: 44px;
  content: ''; }

@media only screen and (max-width: 768px) {
  .swiper-button-prev {
    font-size: 1.286rem;
    top: 55%; }
  .swiper-button-prev:after {
    height: 28px;
    width: 28px;
    background-size: 24px; }
  .swiper-button-next {
    font-size: 1.286rem;
    top: 55%; }
  .swiper-button-next:after {
    background-size: 24px; }
  .swiper-centered-slides .swiper-button-next:after,
  .swiper-centered-slides .swiper-button-prev:after {
    height: 28px;
    width: 28px;
    background-size: 18px; }
  .swiper-parallax .swiper-slide {
    padding: 1rem 1.2rem; }
  .swiper-parallax img {
    height: 100% !important; } }

@media only screen and (max-width: 576px) {
  .swiper-centered-slides.swiper-container .swiper-slide {
    padding: 1.6rem 2.5rem; }
  .swiper-centered-slides.swiper-container .swiper-slide i,
  .swiper-centered-slides.swiper-container .swiper-slide svg {
    height: 1.07rem !important;
    width: 1.07rem !important;
    font-size: 1.07rem !important; }
  .swiper-cube-effect.swiper-container {
    width: 150px;
    left: 70%; }
  .swiper-parallax .swiper-slide {
    padding: 1rem 1.3rem; }
  .swiper-virtual.swiper-container .swiper-slide {
    font-size: 1rem; } }

.dark-layout .swiper-container:not(.swiper-parallax) .swiper-slide {
  background-color: #161d31; }

.dark-layout .swiper-container.swiper-centered-slides .swiper-slide {
  background-color: #283046; }

.dark-layout .swiper-container.swiper-parallax .swiper-slide * {
  color: #6e6b7b; }
